
import type { ElTree } from 'element-plus';
import { parsePermissions2TreeOptions } from '../utils';
import { usePermissions } from '@/composables/api';
import { ref, computed } from '@vue/reactivity';
import { watch, nextTick } from 'vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { data } = usePermissions();
    const treeRef = ref<InstanceType<typeof ElTree>>();
    const checkedKeys = ref([]);

    const getCheckedKeys = () => {
      checkedKeys.value = treeRef.value.getCheckedKeys(false).filter(p => p !== undefined) as string[];
      emit('change', checkedKeys.value);
    };

    watch(data, async() => {
      await nextTick();

      getCheckedKeys();
    });

    const permissionOptions = computed(() => parsePermissions2TreeOptions(data?.value?.data, props.disabled));

    const defaultProps = {
      children: 'children',
      label: 'label'
    };

    return {
      permissionOptions,
      defaultProps,
      getCheckedKeys,
      treeRef
    };
  }
};
