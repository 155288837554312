/* eslint-disable @typescript-eslint/camelcase */
export const translate = {
  channel: '渠道流量',
  'list_channel-analytics': '渠道流量統計',
  list_channels: '渠道列表',
  create_channel: '新增渠道',
  edit_channel: '編輯渠道',
  show_channel: '渠道詳情',
  delete_channel: '刪除渠道',
  list_campaigns: '渠道合作列表',
  create_campaign: '新增渠道合作',
  dictionary: '詞典',
  list_topics: '主題列表',
  create_topic: '新增主題',
  edit_topic: '編輯主題',
  delete_topic: '刪除主題',
  list_tags: '標籤列表',
  create_tag: '新增標籤',
  edit_tag: '編輯標籤',
  delete_tag: '刪除標籤',
  list_directors: '導演列表',
  create_director: '新增導演',
  edit_director: '編輯導演',
  delete_director: '刪除導演',
  list_actors: '演員列表',
  create_actor: '新增演員',
  edit_actor: '編輯演員',
  delete_actor: '刪除演員',
  list_genres: '影片類型列表',
  create_genre: '新增影片類型',
  edit_genre: '編輯影片類型',
  delete_genre: '刪除影片類型',
  events: '活動',
  list_events: '活動列表頁',
  create_event: '新增活動',
  edit_event: '編輯活動',
  delete_event: '刪除活動頁',
  list_event_daily_reports: '活動數據',
  list_event_enrollments: '報名資料',
  preview_event: '活動預覽頁',
  advertisements: '廣告',
  list_advertisements: '廣告',
  create_advertisement: '新增廣告',
  edit_advertisement: '編輯廣告',
  delete_advertisement: '刪除廣告',
  analytics: '分析報表',
  list_analytics: '廣告數據統計',
  'list_landing-page-works': '落地頁影片列表',
  'create_landing-page-work': '新增落地頁預覽片',
  order: '訂單',
  list_orders: '訂單列表',
  permission: '權限',
  list_admins: '帳號列表',
  create_admin: '新增帳號',
  edit_admin: '編輯帳號',
  delete_admin: '刪除帳號',
  list_roles: '角色列表',
  create_role: '新增角色',
  edit_role: '編輯角色',
  delete_role: '刪除角色',
  plan: '訂閱方案',
  list_plans: '方案列表',
  edit_plan: '編輯方案',
  list_coupons: '體驗券列表',
  create_coupon: '新增優惠券',
  edit_coupon: '編輯優惠券',
  delete_coupon: '刪除優惠券',
  list_subscriptions: '訂閱列表',
  'list_subscription-cancellations': '取消續訂記錄',
  user: '會員',
  list_users: '會員列表',
  create_user: '新增會員',
  edit_user: '編輯會員',
  delete_user: '刪除會員',
  show_user: '查看會員詳情',
  'list_review-user': '年齡驗證列表',
  'edit_review-user': '審核年齡驗證',
  'list_browser-videos-history': '用戶觀看紀錄',
  work: '影片',
  list_works: '單部影片列表',
  create_work: '新增影片',
  edit_work: '編輯影片',
  delete_work: '刪除影片',
  show_work: '查看影片詳情',
  'list_feature-works': '主打影片列表',
  'edit_feature-work': '選擇主打影片',
  'list_channel-coupon-usage': '渠道體驗卷統計',
  update_campaign: '渠道合作校正',
  edit_campaign: '編輯渠道合作',
  delete_campaign: '刪除渠道合作',
  edit_subscriptions: '編輯訂閱',
  wte: 'WTE管理',
  list_wte_events: '活動列表',
  show_wte_event: '活動詳細',
  create_wte_event: '新增活動',
  edit_wte_event: '編輯活動',
  delete_wte_event: '刪除活動頁',
  list_suggestions: '建議列表',
  edit_suggestion: '審核建議',
  'list_events-data': '活動數據列表',
  'show_orders-refund': '退款收據',
  'edit_orders-refund': '執行退款',
  'list_wte-statistics': '數據分析',
  'list_wte-withdrawals': '查詢提領批核',
  'edit_wte-withdrawals': '批核提領批核',
  operationLog: '操作日誌',
  'list_operation-logs': '操作日誌列表',
  announcements: '站內通知',
  list_announcements: '站內通知列表',
  create_announcement: '新增通知',
  edit_announcement: '編輯通知',
  delete_announcement: '刪除通知',
  configs: '搜尋推薦主題設定',
  find_config: '取得主題清單',
  update_config: '編輯搜尋推薦主題',
  hotKeywordSearch: '熱門關鍵字',
  'list_hot-keywords': '熱門關鍵字列表',
  'create_hot-keyword': '新增熱門關鍵字',
  'edit_hot-keyword': '變更熱門關鍵字排名',
  'delete_hot-keyword': '刪除熱門關鍵字',
  recommendKeywordSearch: '關鍵字推薦影片',
  'list_recommend-keywords': '關鍵字推薦影片列表',
  'create_recommend-keyword': '新增關鍵字推薦影片',
  'edit_recommend-keyword': '變更關鍵字推薦影片優先度',
  'delete_recommend-keyword': '刪除關鍵字推薦影片',
  searchStatistics: '數據',
  'list_search-statistics': '搜尋數據列表',
  'list_hot-search-statistics': '熱門關鍵字列表',
  'list_recommend-search-statistics': '推薦關鍵字列表',
  'list_organic-search-statistics': '自然搜尋列表',
  'list_work-statistics': '影片播放數列表',
  'list_channel-agent-withdrawals': '提領批核列表及統計',
  'approve_channel-agent-withdrawal': '通過批核提領',
  'update_channel-agent-withdrawal': '更新支出狀態',
  blocks: '場館',
  list_blocks: '場館列表',
  edit_blocks: '場館編輯',
  create_block_topics: '建立場館主題',
  edit_block_topics: '編輯場館主題',
  delete_block_topics: '刪除場館主題',
  create_block_featureWorks: '建立場館主打影片',
  edit_block_featureWorks: '編輯場館主打影片',
  delete_block_featureWorks: '刪除場館主打影片',
  'show_campaign-commission': '查看渠道合作分潤',
  'edit_campaign-commission': '設定渠道合作分潤',
  'list_channel-agents': '代理管理員列表',
  'edit_channel-agent': '編輯代理管理員',
  'create_channel-agent': '新增代理管理員',
  'show_channel-agent': '查看代理管理員詳情',
  'delete_channel-agent': '刪除代理管理員',
  'channel-agents': '代理管理',
  agents: '代理商',
  list_agents: '代理商列表',
  create_agent: '建立代理商',
  edit_agent: '編輯代理商',
  delete_agent: '刪除代理商',
  // 系統設定.
  systems: '系統設定',
  'list_show-exposes': '黃色頁列表',
  'create_show-expose': '建立黃色頁',
  'edit_show-expose': '編輯黃色頁',
  'delete_show-expose': '刪除黃色頁'
};
