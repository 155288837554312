import { Permission } from '@/interfaces/Permission';
import { translate } from './constants';

export function parsePermissions2TreeOptions(permissions: Permission = {}, disabled?: Boolean) {
    interface TreeNode {
        id?: string
        label: string
        disabled?: Boolean
        children?: TreeNode[]
      }

    const options: TreeNode[] = [];

    Object
      .entries(permissions)
      .forEach(([name, opts]) => {
        options.push({
          label: translate[name],
          disabled: disabled,
          children: opts.map(opt => ({
            id: opt,
            label: translate[opt],
            disabled: disabled
          }))
        });
      });

    return options;
}

// export function parsePermissions2GroupingSelectOptions(permissions: Permission[] = []) {
//   interface Options{
//     value: number
//     label: string
//   }

//   interface GroupingSelectOptions {
//   label: string
//   options: Options[]
// }

//   const options: GroupingSelectOptions[] = [];
//   permissions.forEach((p, index) => {
//     console.log('check later');
//     // check if menu exist in options label
//     const target = options.find(o => o.label === p.group);
//     if (target) {
//     // true, push target into children
//       target.options.push({
//         value: p.id,
//         label: p.name
//       });
//       return;
//     }

//     if not, push option
//     options.push({
//       label: p.group,
//       options: [{
//         value: p.id,
//         label: p.name
//       }]
//     });
//   });
//   return options;
// }
