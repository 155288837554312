
/**
 * 根據api的response
 * permissions 裡的權限，判斷組件內的UI是否呈現
 */
import { defineComponent, ref, toRefs } from 'vue';
import { getPermissions } from '@/utils/local-storage';

// export check permission logic
export const canIDo = (whatYouWantToDo: string) => {
  const permissions = getPermissions();
  return process.env.NODE_ENV === 'mock' || permissions.some((permission) => permission === whatYouWantToDo);
};

export default defineComponent({
  props: {
    can: {
      type: String,
      required: true
    }
  },
  emits: ['hasPermission'],
  setup(props, { emit }) {
    const { can } = toRefs(props);
    const isAccessible = ref(false);

    isAccessible.value = canIDo(can.value);

    emit('hasPermission', isAccessible.value);

    return {
      isAccessible
    };
  }
});
